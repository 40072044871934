import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import { Extent } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const ExtentInteraction: FunctionComponent<any> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    extent: undefined,
    boxStyle: undefined,
    pixelTolerance: undefined,
    pointerStyle: undefined,
    wrapX: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    extendchanged: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Extent, props, options, events),
    []
  );

  return null;
};

export default ExtentInteraction;

