// window.CESIUM_BASE_URL = "/static/Cesium"

import React, { useEffect } from 'react';
import proj4 from "proj4";
// import "cesium/Build/Cesium/Widgets/widgets.css";

function CesiumPage() {

  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );

  useEffect(() => {
    Cesium.Ion.defaultAccessToken = null;

    // Viewer
    var viewer = new Cesium.Viewer('cesiumContainer', {
      animation: false,
      baseLayerPicker: false,
      geocoder: false,
      // Ovo ne radi?
      maximumRenderTimeChange: Infinity,
      requestRenderMode: true,
      scene3DOnly: true,
      timeline: false,
      terrainProvider: Cesium.createWorldTerrain()
    });

    // Prehnit d.o.o. logo
    viewer.scene.frameState.creditDisplay.addDefaultCredit(new Cesium.Credit('<a href="https://prehnit.hr"><img src="logo.png"/></a>'));

    // Cesium tileset
    var tileset = new Cesium.Cesium3DTileset({
      //debugShowBoundingVolume: true,
      //debugShowGeometricError: true,
      //debugShowRenderingStatistics: true,
      dynamicScreenSpaceError: true,
      immediatelyLoadDesiredLevelOfDetail: true,
      maximumScreenSpaceError: 1,
      skipLevelOfDetail: true,
      url: "/cesium/Holcim.json",
    });

    // Konfiguriraj što radi home gumb
    viewer.homeButton.viewModel.command.beforeExecute.addEventListener(function (commandInfo) {
      viewer.flyTo(tileset);
      commandInfo.cancel = true;
    });

    // Dodaj tilest
    viewer.scene.primitives.add(tileset);

    // Zoomiraj na tilest
    viewer.zoomTo(tileset);
  }, [])


  return (
    <div id="cesiumContainer">
    </div>
  );
}

export default CesiumPage;