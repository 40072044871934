import * as React from "react";
import { Fragment, FunctionComponent } from "react";

//Custom components
import ZoomControl from "./OpenLayers/ZoomControl";
import AttributionControl from "./OpenLayers/AttributionControl";
import RotateControl from "./OpenLayers/RotateControl";
import { getDefinedOptions } from "@/lib/olHelpers";

//Types
import { DefaultsOptions } from "ol/control";

const DefaultControls: FunctionComponent<any> = (props) => {
  const options = {
    attribution: true,
    attributionOptions: undefined,
    rotate: true,
    rotateOptions: undefined,
    zoom: true,
    zoomOptions: undefined
  };

  const allOptions = Object.assign(options, props);
  const definedOptions: DefaultsOptions = getDefinedOptions(allOptions);

  return (
    <Fragment>
      {definedOptions.attribution === true ? (
        <AttributionControl {...definedOptions.attributionOptions} />
      ) : null}
      {definedOptions.rotate === true ? (
        <RotateControl {...definedOptions.rotateOptions} />
      ) : null}
      {definedOptions.zoom === true ? <ZoomControl {...definedOptions.zoomOptions} /> : null}
    </Fragment>
  );
};

export default DefaultControls;

