import { IModel } from "@/@types/models/model";
import { buttonFields } from "@/models/helpers/buttonFields";
import { commonFields } from "./helpers/commonFields";
import { coreFields } from "./helpers/coreFields";

const Prikljucci: IModel = {
  title: "Prikljucci",
  source: "prikljucci",
  apiPath: "prikljucci/",
  forms : {
    "default": {
      fields: ["id", "naziv"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "naziv"],
      hidden: ["id"]
    },
    "tooltip": {
      fields: ["id", "naziv"],
    },
    "attribute_table": {
      fields: ["id", "naziv"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Naziv",
      source: "naziv",
      ttoken: "common.naziv",
      type: "text",
      readonly: true
    },
    commonFields.wkt,
    buttonFields.deselect
  ]
}

export default Prikljucci;
