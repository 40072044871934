import { FunctionComponent, useContext, useEffect } from "react";
import MapContext from "@/components/Map/MapContext";

//OpenLayers
import OverviewMap from "ol/control/OverviewMap";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

//Custom components
import { registerOlControl } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IOverviewMapControl } from "@/@types/components/Map/Controls/OpenLayers";

const OverviewMapControl: FunctionComponent<IOverviewMapControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    className: undefined,
    collapsed: undefined,
    collapseLabel: undefined,
    collapsible: undefined,
    label: undefined,
    layers: [new TileLayer({ source: new OSM()})],
    render: undefined,
    rotateWithView: undefined,
    target: undefined,
    tipLabel: undefined,
    view: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, OverviewMap, props, options, events), [
    props.className,
    props.collapsed,
    props.collapseLabel,
    props.collapsible,
    props.label,
    props.layers,
    props.render,
    props.rotateWithView,
    props.target,
    props.tipLabel,
    props.view
  ]);

  return null;
};

export default OverviewMapControl;

