import { IFieldButton } from "@/@types/models/model";

const view: IFieldButton = {
  title: "",
  source: "view",
  tooltip: "buttons.view",
  ttoken: "",
  type: "button",
  icon: "open_in_new",
  action: "view",
  padding: "none",
  sort: false
};

const edit: IFieldButton = {
  title: "",
  source: "edit",
  tooltip: "buttons.edit",
  ttoken: "",
  type: "button",
  icon: "edit",
  action: "edit",
  padding: "none",
  sort: false
};

const remove: IFieldButton = {
  title: "",
  source: "remove",
  tooltip: "buttons.remove",
  ttoken: "",
  type: "button",
  icon: "delete_sweep",
  action: "remove",
  padding: "none",
  sort: false
}

const print: IFieldButton = {
  title: "",
  source: "print",
  tooltip: "buttons.print",
  ttoken: "",
  type: "button",
  icon: "print",
  action: "print",
  padding: "none",
  sort: false
}

const download: IFieldButton = {
  title: "",
  source: "download",
  tooltip: "buttons.download",
  ttoken: "",
  type: "button",
  icon: "file_download",
  action: "download",
  padding: "none",
  sort: false
}

const deselect: IFieldButton = {
  title: "",
  source: "deselect",
  tooltip: "buttons.deselect",
  ttoken: "",
  type: "button",
  icon: "deselect",
  action: "deselect",
  padding: "none",
  sort: false
}

export const buttonFields = {
  view: view,
  edit: edit,
  remove: remove,
  print: print,
  download: download,
  deselect: deselect
};
