import { FunctionComponent, useContext, useEffect } from "react";

import { Modify } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const ModifyInteraction: FunctionComponent<any> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    condition: undefined,
    deleteCondition: undefined,
    insertVertexCondition: undefined,
    pixelTolerance: undefined,
    style: undefined,
    source: undefined,
    features: undefined,
    wrapX: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    modifyend: undefined,
    modifystart: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Modify, props, options, events),
    []
  );

  return null;
};

export default ModifyInteraction;

