import * as React from "react";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";

//Custom Components
import PickerControl from "@/controls/PickerControl";
import FormButton from "@/ui/Form/FormButton";
import picker from "@/models/submodels/picker";
import { GridContainer, GridItem } from "@/ui/Grid";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import MapConfigContext from "@/components/MapConfigContext/MapConfigContext";

//Types
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";
import { MapConfigContexType } from "@/@types/context/MapConfigContext";
import { PickerItem } from "@/@types/controls/controls";
import { ISubmodel } from "@/@types/models/submodel";
import { IFieldPickerModel, PickerItemValue } from "@/@types/models/model";

const SearchPane: FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const mapConfigContext = useContext(MapConfigContext) as MapConfigContexType;

  const [value, setValue] = useState<PickerItem | null>(null);
  const [subModels, setSubModels] = useState<ISubmodel | null>(null);

  const handleChange = (option: PickerItem | PickerItemValue | null, source: string) => {
    if (option && option.hasOwnProperty("value")) {
      setValue(option as PickerItem);
    } else {
      setValue(null);
    }
  }

  // const handleAddLayer = (evt, action) => {
  //   if (value && action) {
  //     const res = action(value.value, value.label);
  //     parseResponse(res);
  //   }
  // }
  //
  // const handleReplaceLayer = (evt, action) => {
  //   if (value && action) {
  //     const res = action(value.value, value.label);
  //     parseResponse(res);
  //   }
  // }

  // const parseResponse = (res) => {
  //   if (res.success) {
  //     switch (res.result) {
  //       case "add":
  //         snackbarContext.showNotification("Vrsta je dodana na kartu.", "success");
  //         break;
  //       case "remove":
  //         snackbarContext.showNotification("Vrsta je uklonjena s karte.", "success");
  //         break;
  //       case "set":
  //         snackbarContext.showNotification("Vrsta je postavljena na kartu.", "success");
  //         break;
  //     }
  //   } else {
  //     switch (res.reason) {
  //       case "maxSelect":
  //         snackbarContext.showNotification(
  //           "Već je dodan maksimalan broj vrsti na kartu.",
  //           "error"
  //         );
  //         break;
  //       case "alreadyAdded":
  //         snackbarContext.showNotification("Vrsta je već dodana na karti.", "warning");
  //         break;
  //       default:
  //         snackbarContext.showNotification("Došlo je do greške.", "error");
  //         break;
  //     }
  //   }
  // }

  const model =
    subModels && subModels.hasOwnProperty("vrsta_id")
      //@ts-ignore
      ? subModels["vrsta_id"]
      : undefined;

  const actionDisabled = value === undefined || value === null;

  const field: IFieldPickerModel = {
    title: "Znanstveni naziv vrste",
    source: "vrsta_id",
    ttoken: "common.naziv_vrste_znanstveni",
    type: "picker",
    subModel: {
      apiPath: "katalog/vrste-for-picker",
      format: "{label}",
    },
    width: "full",
  };

  return (
        <GridContainer>
          <GridItem xs={12}>
            <PickerControl
              field={field}
              formMode="form"
              controlMode="edit"
              value={value}
              isLoading={!subModels}
              //@ts-ignore TODO: Once we change PickerControl to TS, we can remove ignore
              model={model}
              onChange={handleChange}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormButton
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={(evt) => {}}
              disabled={actionDisabled}
            >
              {t("map.add_layer")}
            </FormButton>
          </GridItem>
          <GridItem xs={12}>
            <FormButton
              variant="outlined"
              startIcon={<SyncIcon />}
              onClick={(evt) => {}}
              disabled={actionDisabled}
            >
              {t("map.samostalno")}
            </FormButton>
          </GridItem>
          {/*<GridItem xs={12}>*/}
          {/*  <Typography variant="caption">*/}
          {/*    {t("map.current_species")}:{" "}*/}
          {/*    {selected_vrste_ids ? selected_vrste_ids.length : "0"} /{" "}*/}
          {/*    {maxSelVrsta}*/}
          {/*  </Typography>*/}
          {/*</GridItem>*/}
          {/*<GridItem xs={12}>*/}
          {/*  <Typography variant="h6">*/}
          {/*    {selected_vrste_ids && selected_vrste_ids.length > 0*/}
          {/*      ? "Odabrane strane vrste"*/}
          {/*      : null}*/}
          {/*  </Typography>*/}
          {/*</GridItem>*/}
          {/*{selected_vrste_ids && selected_vrste_ids.length > 0 ? (*/}
          {/*  <GridItem xs={12}>*/}
          {/*    <GridContainer spacing={0}>*/}
          {/*      {getVrsteLabels(selected_vrste_ids).map((vrsta, i) => {*/}
          {/*        return (*/}
          {/*          <GridItem key={"label-" + i} xs={12}>*/}
          {/*            <Typography variant="caption">- {vrsta.label}</Typography>*/}
          {/*            <IconButton*/}
          {/*              size="small"*/}
          {/*              onClick={() => toggleVrsta(vrsta.value)}*/}
          {/*            >*/}
          {/*              <CloseIcon fontSize="small" />*/}
          {/*            </IconButton>*/}
          {/*          </GridItem>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </GridContainer>*/}
          {/*  </GridItem>*/}
          {/*) : null}*/}
        </GridContainer>
  );
};

export default SearchPane;

