import * as React from "react";
import { useContext, Fragment, FunctionComponent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

//Types
import { ILegendPane } from "@/@types/components/MapSidebarPanes";

const apiUrl = process.env.REACT_APP_GEOSERVERPATH;

const LegendPane: FunctionComponent<ILegendPane> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme() as any;

  const { layersCollection } = props;

  const getLegend = (layer: string): string => {
    const bgColor = theme.palette.background.default.substring(1);
    const fontColor = theme.palette.mode == "light" ? "000000" : "FFFFFF";
    return `${apiUrl}?REQUEST=GetLegendGraphic&VERSION=1.0.0&`
            + `FORMAT=image/png&`
            + `WIDTH=20&`
            + `HEIGHT=20&`
            + `LAYER=${layer}`
            + `&legend_options=`
              + `layout:vertical;`
              + `bgColor:0x${bgColor};`
              + `fontColor:0x${fontColor};`;
  }

  return (
    <Fragment>
      { layersCollection ?
        layersCollection.getArray().map((layer: any, i: number) => 
          layer.getProperties().legend ?
            <Box key={i}>
              <Typography variant="h6">
                {t(layer.getProperties().ttoken_legend)}
              </Typography>
              <img 
                src={getLegend(layer.getProperties().layer)}
              />
            </Box>
          : null 
      ) : null }
    </Fragment>
  );
};

export default LegendPane;

