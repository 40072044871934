import { IModel } from "@/@types/models/model";
import { buttonFields } from "@/models/helpers/buttonFields";
import { commonFields } from "./helpers/commonFields";
import { coreFields } from "./helpers/coreFields";

const Objekti: IModel = {
  title: "Objekti",
  source: "objekti",
  apiPath: "objekti/",
  forms : {
    "default": {
      fields: ["id", "naziv", "namjena"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "naziv", "namjena", "visina", "napomena", "grijano"],
      hidden: ["id"]
    },
    "tooltip": {
      fields: ["id", "naziv", "namjena", "visina", "napomena", "grijano"],
    },
    "attribute_table": {
      fields: ["id", "naziv", "namjena", "visina", "napomena", "grijano"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "Naziv",
      source: "naziv",
      ttoken: "common.naziv",
      type: "text",
      readonly: true
    },
    {
      title: "Namjena",
      source: "namjena",
      ttoken: "objekti.namjena",
      type: "text",
      readonly: true
    },
    {
      title: "visina",
      source: "visina",
      ttoken: "objekti.visina",
      type: "numeric",
      readonly: true
    },
    {
      title: "napomena",
      source: "napomena",
      ttoken: "common.napomena",
      type: "text",
      readonly: true
    },
    {
      title: "grijano",
      source: "grijano",
      ttoken: "objekti.grijano",
      type: "text",
      readonly: true
    },
    commonFields.wkt,
    buttonFields.deselect
  ]
}

export default Objekti;
