import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI Components
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  ElementPropertyFilterWrapperFn,
  PickerValue,
} from "@/@types/ui/Table";
import { Row } from "react-table";

export const ElementPropertyColumnFilter: ElementPropertyFilterWrapperFn = 
(setFilterLocalStorage, dc) => ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
  const { t } = useTranslation();

  const _field = dc.getField(id);
  const _fieldItems = _field.items;
  
  const options = React.useMemo(() => {
    const options = new Set();
    _fieldItems.labels.filter((label: string, i: number) => {
      const foundValue = preFilteredRows.find(
        (pfr: Row) => pfr.values[id].props.property === _fieldItems.values[i]
      );
      if(foundValue !== undefined) {
        options.add({
          value: _fieldItems.values[i],
          label: label
        })
      }
    });
    return Array.from(options.values());
  }, [id, preFilteredRows]);

  return (
    <Select
      value={filterValue}
      onChange={(e, value) => {
        setFilterLocalStorage(id, value, setFilter);
      }}
      variant="outlined"
      defaultValue=""
      margin="dense"
      displayEmpty
    >
      <MenuItem value="">{t("common.all")}</MenuItem>
      {
      	(options as Array<PickerValue>).map((option, i) => (
	        <MenuItem key={i} value={option.value}>
	          {t(option.label)}
	        </MenuItem> 
	      ))
      }
    </Select>

  );
}
