import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import { GridContainer } from "@/ui/Grid";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const textVariant = "caption";
  const version = process.env.REACT_APP_APPVERSION;
  const language = i18n.language;
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer">
      <Toolbar
        disableGutters={false}
        sx={{
          borderTop: "2px solid #000",
          backgroundColor: "#fff"
        }}
      >
        <GridContainer spacing={0} />
      </Toolbar>
    </footer>
  );
};

export default Footer;
