import { IModel } from "@/@types/models/model";
import { buttonFields } from "@/models/helpers/buttonFields";
import { commonFields } from "./helpers/commonFields";
import { coreFields } from "./helpers/coreFields";

const Ploce: IModel = {
  title: "Signalizacija",
  source: "signalizacija",
  apiPath: "data/signalizacija",
  forms : {
    "default": {
      fields: ["id", "vrsta_kart", "dimenzije"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "naziv", "broj", "vrsta_kart", "dimenzije"],
      hidden: ["id"]
    },
    "tooltip": {
      fields: ["id", "naziv", "broj", "vrsta_kart", "dimenzije"],
    },
    "attribute_table": {
      fields: ["id", "naziv", "broj", "vrsta_kart", "dimenzije"],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    {
      title: "naziv",
      source: "naziv",
      ttoken: "common.naziv",
      type: "text",
      readonly: true
    },
    {
      title: "broj",
      source: "broj",
      ttoken: "ploce.broj",
      type: "numeric",
      readonly: true
    },
    {
      title: "Vrsta karte",
      source: "vrsta_kart",
      ttoken: "ploce.vrsta_signalizacije",
      type: "text",
      readonly: true
    },
    {
      title: "dimenzije",
      source: "dimenzije",
      ttoken: "ploce.dimenzije",
      type: "text",
      readonly: true
    },
    commonFields.wkt,
    buttonFields.deselect
  ]
}

export default Ploce;
