import * as React from "react";
import { FunctionComponent, useContext, useEffect, useRef } from "react";

//MUI Core Components
import Box from "@mui/material/Box";

//Custom components
import MapContext from "@/components/Map/MapContext";
import AttributeTableClass from "./attributetableclass";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IAttributeTableRoot } from "@/@types/components/Map/Controls/AttributeTable";
import Control from "ol/control/Control";

const AttributeTableRoot: FunctionComponent<IAttributeTableRoot> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { ref, id } = props;

  const AttributeTableRef = useRef(ref);

  useEffect(() => {
    if (AttributeTableRef.current) {
      const options = {
        element: AttributeTableRef.current
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new AttributeTableClass(definedOptions);

      if (id) {
        control.set("id", id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, AttributeTableClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control as Control);
      } else {
        mapContext.initOptions.controls.push(control as Control);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, AttributeTableClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  const width = mapContext.sidebarCollapsed ? "calc(100% - 550px)" : "calc(100% - 1000px)"

  return (
    <Box
      ref={AttributeTableRef}
      sx={{
        display: "inline",
        position: "absolute",
        width: width,

        minHeight: "20px",
        maxHeight: "50%",
        background: "transparent",
        bottom: 10,
        right: 0,
        margin: "auto",
        mb: 0,
        ml: "200px",
        mr: "350px",
        borderRadius: "4px",
        textAlign: "center",
        pr: "10px"
      }}
    >
      {props.children}
    </Box>
  );
};

export default AttributeTableRoot;
