import { FunctionComponent, useContext, useEffect } from "react";

import MousePosition from "ol/control/MousePosition";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IMousePositionControl } from "@/@types/components/Map/Controls/OpenLayers";

const MousePositionControl: FunctionComponent<IMousePositionControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    className: undefined,
    coordinateFormat: undefined,
    projection: undefined,
    render: undefined,
    target: undefined,
    undefinedHTML: undefined
  };

  const events = {
    change: undefined,
    "change:coordinateFormat": undefined,
    "change:projection": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, MousePosition, props, options, events), [
    props.className,
    props.coordinateFormat,
    props.projection,
    props.render,
    props.target,
    props.undefinedHTML
  ]);

  return null;
};

export default MousePositionControl;

