import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers ext
import Toggle from "ol-ext/control/Toggle";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";
import BarControlContext from "./Bar/BarControlContext";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { BarControlContextType } from "@/@types/components/Map/Controls/OpenLayersExt/Bar";
import { IToggleControl } from "@/@types/components/Map/Controls/OpenLayersExt";

const ToggleControl: FunctionComponent<IToggleControl> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const parentBarContext = useContext(BarControlContext) as BarControlContextType;

  const options = {
    className: undefined,
    title: undefined,
    html: undefined,
    interaction: undefined,
    active: undefined,
    disable: undefined,
    bar: undefined,
    autoActive: undefined,
    onToggle: undefined,
  };

  const events = {
    "change:active": undefined,
    "change:disabled": undefined,
  };

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    let control = new Toggle(definedOptions);

    if (props.id) {
      control.set("id", props.id);
    }

    if (parentBarContext && parentBarContext.exists) {
      parentBarContext.childControls.push(control);
    } else if (mapContext.map) {
      const mapControl = findControl(mapContext.map, props.id, Toggle);
      if (mapControl) {
        mapContext.map.removeControl(mapControl);
        console.log("control removed", Toggle);
      }
      mapContext.map.addControl(control);
      console.log("control added", Toggle);
    } else {
      mapContext.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Toggle);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, [
    props.className,
    props.title,
    props.html,
    props.interaction,
    props.active,
    props.disable,
    props.bar,
    props.autoActive,
    props.onToggle,
  ]);

  return null;
};

export default ToggleControl;

