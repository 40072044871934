import { standardHeadersWithAuth } from "./standardHeadersWithAuth";
import { downloadHeadersWithAuth } from "./downloadHeadersWithAuth";
import { handleResponse } from "./handleResponse";
import { IApi, IFileResponse, IApiResponse, IApiResponseFailure, MethodVerbs } from "../../@types/lib/api/api";


const apiUrl = process.env.REACT_APP_APIPATH;

class api implements IApi {

  constructor() {

  }

  _get(path: string): Promise<IApiResponse> {
    return fetch(path, {
      method: "GET",
      headers: standardHeadersWithAuth()
    })
      .then(handleResponse)
      .then(jsondata => {
        return Promise.resolve({ success: true, data: jsondata });
      })
      .catch(error => {
        return Promise.reject({ success: false, error: error });
      })
      .finally(() => {
      });
  };

  _post(path: string, data: object): Promise<IApiResponse> {

    return fetch(path, {
      method: "POST",
      mode: "cors",
      headers: standardHeadersWithAuth(),
      body: JSON.stringify(data)
    })
      .then(handleResponse)
      .then(jsondata => {
        return Promise.resolve({ success: true, data: jsondata });
      })
      .catch(error => {
        return Promise.reject({ success: false, error: error });
      })
      .finally(() => {
      });
  };

  _put(path: string, data: object): Promise<IApiResponse> {

    return fetch(path, {
      method: "PUT",
      mode: "cors",
      headers: standardHeadersWithAuth(),
      body: JSON.stringify(data)
    })
      .then(handleResponse)
      .then(jsondata => {
        return Promise.resolve({ success: true, data: jsondata });
      })
      .catch(error => {
        return Promise.reject({ success: false, error: error });
      })
      .finally(() => {
      });
  };

  _patch(path: string, data: object): Promise<IApiResponse> {

    return fetch(path, {
      method: "PATCH",
      mode: "cors",
      headers: standardHeadersWithAuth(),
      body: JSON.stringify(data)
    })
      .then(handleResponse)
      .then(jsondata => {
        return Promise.resolve({ success: true, data: jsondata });
      })
      .catch(error => {
        return Promise.reject({ success: false, error: error });
      })
      .finally(() => {
      });
  };

  _delete(path: string, data: object): Promise<IApiResponse> {

    return fetch(path, {
      method: "DELETE",
      mode: "cors",
      headers: standardHeadersWithAuth(),
      body: JSON.stringify(data)
    })
      .then(handleResponse)
      .then(jsondata => {
        return Promise.resolve({ success: true, data: jsondata });
      })
      .catch(error => {
        return Promise.reject({ success: false, error: error });
      })
      .finally(() => {
      });
  };

  Call(path: string, action: MethodVerbs, data?: object): Promise<IApiResponse> {
    const url = `${apiUrl}${path}`;

    const d = data !== undefined ? data : {};

    switch (action) {
      case "get":
        return this._get(url);
      case "post":
        return this._post(url, d);
      case "put":
        return this._put(url, d);
      case "patch":
        return this._patch(url, d);
      case "delete":
        return this._delete(url, d);
      default:
        console.error("Wrong action was provided, expected one of: ", '["get", "post", "put", "patch", "delete"] ', '@api.js @Call');
        return new Promise(() => {});
    }
  };

  Download(typeOrUrl: string, key?: string): Promise<IFileResponse> {
    const url = key ? `${apiUrl}${typeOrUrl}/${key}` : `${apiUrl}${typeOrUrl}`;
    return fetch(url, {
      method: "GET",
      mode: "cors",
      headers: downloadHeadersWithAuth()
    })
      .then(data => {
        const tmp = data.blob();
        return Promise.resolve({ success: true, data: tmp });
      })
      .catch(error => {
        return Promise.reject({ success: false, error: error });
      });
  };

}

export default api;
