import React from "react";
import { useAsyncDebounce } from "react-table";
import { useTranslation } from "react-i18next";

//MUI
import TextField from "@mui/material/TextField";

//MUI Icons
import SearchIcon from "@mui/icons-material/Search";

//Types
import { ITableSearchBarProps, GlobalFilterFn } from "@/@types/ui/Table";

function TableSearchBar(props: ITableSearchBarProps) {
  const { t } = useTranslation();
  const { preGlobalFilteredRows, globalFilter, setGlobalFilter } = props;
  const [value, setValue] = React.useState<GlobalFilterFn>(globalFilter);

  const count = props.count !== undefined ? props.count : preGlobalFilteredRows.length;

  //actions that will manipulate the table state may result in multiple rapid rerenders
  //useAsyncDebounce will allow you to debounce rapid side-effects and only use the latest one
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // // Here's where the API call happens
  // // We use useEffect since this is an asynchronous action
  // useEffect(
  //   () => {
  //     // Make sure we have a value (user has entered something in input)
  //     console.log(value, searchTerm);
  //     if (debouncedSearchTerm) {
  //       onChange(debouncedSearchTerm);
  //     } else {
  //       onChange(undefined);
  //     }
  //   },
  //   // This is the useEffect input array
  //   // Our useEffect function will only execute if this value changes ...
  //   // ... and thanks to our hook it will only change if the original ...
  //   // value (searchTerm) hasn't changed for more than 500ms.
  //   [debouncedSearchTerm, searchTerm]
  // );

  const placeholder = t("common.search_count_placeholder", { count: count });

  return (
    <TextField
      id="global-search"
      InputProps={{
        startAdornment: <SearchIcon color="primary" />
      }}
      variant="standard"
      color="primary"
      margin="dense"
      placeholder={placeholder}
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      sx={{
        ml: 2
      }}
    />
  );
}

export default TableSearchBar;
