import React from "react";

//Custom Components
import PhotoCentricPage from "@/views/PhotoCentric/PhotoCentricPage";
import modelObjekti from "@/models/objekti";
import { Permissions as P } from "@/lib/permissions";

type ObjektiPhotoCentricPageParams = {}

function ObjektiPhotoCentricPage(props: ObjektiPhotoCentricPageParams) {
  return (
    <PhotoCentricPage
      needPermission={[P.ManageKN]}
      titleToken={"titles.objekti"}
      model={modelObjekti}
      mapId={21}
      />
  );
}

export default ObjektiPhotoCentricPage;
