import HomePage from "@/views/HomePage/HomePage";
import MapPage from "@/views/MapPage/MapPage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import AdminHomePage from "@/views/Administration/AdminHomePage";
import UsersPage from "@/views/Administration/UsersPage/UsersPage";

import ObjektiPhotoCentricPage from "@/views/Objekti/ObjektiPhotoCentricPage";
import SignalizacijaPhotoCentricPage from "@/views/Signalizacija/SignalizacijaPhotoCentricPage";
import ThreeDPage from "@/views/ThreeD/ThreeDPage";


//Types
import { RouteType } from "@/@types/common";

const privateRoutes: RouteType[] = [
  { path: "/profile", name: "Profile", component: ProfilePage},
	{ path: "/admin/users", name: "Map", component: UsersPage },
  { path: "/admin", name: "Admin Home Page", component: AdminHomePage },
  { path: "/map", name: "Map", component: MapPage },
  { path: "/objekti", name: "Objekti", component: ObjektiPhotoCentricPage },
  { path: "/signalizacija", name: "Signalizacija", component: SignalizacijaPhotoCentricPage },
  { path: "/3d", name: "3D", component: ThreeDPage },
	{ path: "/", name: "Home", component: MapPage }
];

export default privateRoutes
