import React from "react";

//Custom Components
import PhotoCentricPage from "@/views/PhotoCentric/PhotoCentricPage";
import modelPloce from "@/models/ploce";
import { Permissions as P } from "@/lib/permissions";

type SignalizacijaPhotoCentricPageParams = {}

function SignalizacijaPhotoCentricPage(props: SignalizacijaPhotoCentricPageParams) {
  return (
    <PhotoCentricPage
      // needPermission={[P.Signalizacija]}
      titleToken={"titles.signalizacija"}
      model={modelPloce}
      mapId={22}
      />
  );
}

export default SignalizacijaPhotoCentricPage;
