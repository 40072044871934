import * as React from "react";
import { useContext, Fragment, FunctionComponent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import FormButton from "@/ui/Form/FormButton";

//Material-UI Icons
import AddIcon from "@mui/icons-material/Add";

// Custom Components
import MapConfigContext from "@/components/MapConfigContext/MapConfigContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
// import LayerSwitcher from "@/components/Map/Controls/Custom/LayerSwitcher/LayerSwitcher";

//Types
import { MapConfigContexType } from "@/@types/context/MapConfigContext";
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";
import { ILayersPane } from "@/@types/components/MapSidebarPanes";
import { IApiResponse } from "@/@types/lib/api/api";

const LayersPane: FunctionComponent<ILayersPane> = (props) => {
  const { t } = useTranslation();
  const mapConfigContext = useContext(MapConfigContext) as MapConfigContexType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const { layersGroup } = props;

  const handleAddExternalLayer = (evt: SyntheticEvent): void => {
    // evt.currentTarget.blur();
    // showDialog(MapAddLayerDialog, {
    //   onClose: handleDialogResult,
    // });
  };

  const handleDialogResult = (result: IApiResponse): void => {
    if (result.success) {
      mapConfigContext.addLayer(result.data);
      snackbarContext.showNotification("Sloj je uspješno odabran.", "success");
    } else if (result.error) {
      snackbarContext.showNotification("Došlo je do greške prilikom odabira sloja", "error");
    }
  };

  return (
    <Fragment>
      {/* <LayerSwitcher ready={!!layersGroup} /> */}
      <FormButton
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddExternalLayer}
      >
        {t("buttons.add")}
      </FormButton>
    </Fragment>
  );
};

export default LayersPane;

