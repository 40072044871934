import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import { DragAndDrop } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const DragAndDropInteraction: FunctionComponent<any> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    formatConstructors: undefined,
    source: undefined,
    projection: undefined,
    target: undefined
  };

  const events = {
    addfeatures: undefined,
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, DragAndDrop, props, options, events),
    []
  );

  return null;
};

export default DragAndDropInteraction;

