import React, { Fragment, ReactNode } from "react";
import { TFunction } from "i18next";
import moment from "moment";
import numbro from "numbro";

// MUI Components
import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";

// Custom Components
import { fieldFormat } from "@/lib/fieldFormat";
import { formats } from "@/lib/formats";
import { helpers } from "@/lib/helpers";
import {
  BoolFn,
  VoidFn,
  RenderFn,
  ISubModelsWithData,
  RowActionFn,
  AllowRowActionFn
} from "@/@types/ui/Table";
import { FieldAny } from "@/@types/models/model";

type CustomFn = (id: number) => void;

class tableGenerator {
  t: TFunction;
  onAction: RowActionFn;
  allowAction: AllowRowActionFn;
  constructor(t: TFunction, rowActionHandler: RowActionFn, allowRowAction: AllowRowActionFn) {
    this.t = t;
    const emptyFnc: VoidFn = (action, id) => {};
    const alwaysAllow: BoolFn = (action, id) => (true);

    this.onAction = rowActionHandler || emptyFnc;
    this.allowAction = allowRowAction || alwaysAllow;
  }

  generateRenderFunction(field: FieldAny, dc: ISubModelsWithData, customFn?: CustomFn): RenderFn {
    const fieldDc = dc[field.source];
    switch (field.type) {
      case "text":
      case "wholenum":
      case "multiline":
        return ({ value, trimString }) => {
          trimString = trimString ? trimString : (v) => v ? v : ""
          if (field.items) {
            let pos = field.items.values.indexOf(value);
            const lbl = field.items.labels[pos];
            return trimString(field.translate ? this.t(lbl) : lbl ? lbl : "");
          // format not defined on these types for now
          // } else if (field.format) {
          //   return fieldFormat(value, field.format, field.translate ? this.t : (v) => v);
          } else {
            return trimString(field.translate ? this.t(value) : value ? value : "");
          }
        };
      case "date":
        return ({ value }) => (
          value ? moment.utc(value).local().format(formats.date) : "-"
        );
      case "datetime":
        return ({ value }) => (
          value ? moment.utc(value).local().format(formats.datetime) : ""
        );
      case "currency":
        return ({ value }) => {
          if (value && typeof value === "object") {
            if (value._value !== undefined && value._value !== null) {
              const num = numbro(value._value).format({ thousandSeparated: true, mantissa: 2 });
              return (
                <div style={{ textAlign: "right" }}>
                  {num}
                </div>
              );
            } else {
              return value;
            }
          } else if (typeof value === "number") {
            const num = numbro(value).format({thousandSeparated: true, mantissa: 2});
            return (
              <div>
                {field.currency} {num}
              </div>
            );
          } else {
            return null;
          }
        }
      case "active":
        return ({ value }) => {
          if (field.items) {
            let pos = field.items.values.indexOf(value);
            return this.t(field.items.labels[pos]);
          } else {
            return value;
          }
        }
      case "boolean":
      case "radio":
        return ({ value }) => {
          if (fieldDc) {
            if (value === null || value === undefined) {
              return "";
            } else {
              const rec = fieldDc.records.find((x) => x.value === value)
              return rec ? this.t(rec.label) : "";
            }
          } else if (field.items) {
            const ind = field.items.values.indexOf(value && value.hasOwnProperty("value") ? value.value : value);
            return this.t(field.items.labels[ind]);
          } else {
            return value;
          }
        }
      case "checkbox":
        return ({ value }) => {
          if (Number.isInteger(value)) {
            if (fieldDc) {
              const rec = fieldDc.records.find((x) => x.value == value);
              return rec ? this.t(rec.label) : "";
            }
            const { items } = field;
            if (!items) {
              return null
            }
            // extract powers of two from value and add according labels to the codes array
            const codes = [];
            let i = 0;
            while (value) {       // it will eventually decrease to 0
              if (value & 1) {    // if smallest value bit is 1
                const p = 1 << i; // Math.pow(2, i);
                codes.push(this.t(items.labels[items.values.indexOf(p)]));
              }
              ++i;
              value >>= 1;        // value = Math.floor(value / 2);
            }
            return codes.join(", ");
          } else if (Array.isArray(value)) {
            if (fieldDc) {
              return value.map((x) => {
                const rec = fieldDc.records.find((f) => f.value === x || f.value === x.value)
                return rec ? this.t(rec.label) : "";
              }).filter(a => a !== "").join(", ");
            } else {
              const { items } = field;
              if (items) {
                const labels = value.map((x) => {
                  if (items.labels.indexOf(x)) {
                    return this.t(items.labels[x]);
                  } else {
                    return null
                  }
                }).filter(x => x !== null);
                return labels.join(", ");
              } else {
                return value;
              }
            }
          } else {
            return value;
          }
        }
      case "picker":
        return ({ value }) => {
          if (fieldDc) {
            if (value === null || value === undefined) {
              return "";
            } else if (Array.isArray(value) && field.multi) {
              const labels = value.map((val) => {
                const rec = fieldDc.records.find((x) => x.value === val);
                return rec ? this.t(rec.label) : ""
              });
              return labels.join(", ");
            } else {
              // const rec = fieldDc.records.find((x) => x.value === (val.value || value));
              const rec = fieldDc.records.find((x) => x.value === value || (value.hasOwnProperty("value") && value.value === x.value));
              return rec ? this.t(rec.label) : "";
            }
          } else if (value && value.hasOwnProperty("value")) {
            return value.value;
          } else if (field.items) {
            const ind = field.items.values.indexOf(value);
            const valueLabel = this.t(field.items.labels[ind]);
            return field.items.icons ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Icon fontSize="small">{field.items.icons[ind]}</Icon>
                  <div style={{ fontSize: "14px", marginLeft: "4px" }}>{valueLabel}</div>
                </div>
              ) : valueLabel;
          } else {
            return value;
          }
        };
      case "array":
        return ({value}) => {
          return Array.isArray(value) ?
            value.map((x) => this.t(x)).join(", ")
            : this.t(value);
        }
      case "button":
        return ({ value, row }) => {
          const idAttribute = field.idref ? field.idref : "id";
          const allow = this.allowAction(field.action, row.original[idAttribute] as number);
          const title = field.tooltip ? this.t(field.tooltip) : "";
          return allow ? (
            <Tooltip title={title ? title : ""}>
              <IconButton
                aria-label={field.action}
                size="small"
                disabled={!allow}
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.onAction(field.action, row.original[idAttribute] as number, row.original);
                }}
              >
                <Icon color="primary">{field.icon}</Icon>
              </IconButton>
            </Tooltip>
          ) : null;
        }
      case "link":
        return ({ value, row }) => {
          if (value) {
            const { recordIdField, recordType } = field.link;
            const recordId = row.original.hasOwnProperty(recordIdField) ? row.original[recordIdField] as number : 0;
            return (
              <Fragment>
                {value}
                <IconButton
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    this.onAction(recordType, recordId);
                  }}
                >
                  <Icon color="primary">link</Icon>
                </IconButton>
              </Fragment>
            );
          } else {
            return null;
          }
        }
      case "mail":
        return ({ value }) => {
          if (value) {
            return (
              <Fragment>
                <IconButton
                  aria-label={"mail"}
                  size="small"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    window.open("mailto:" + value, "_blank");
                  }}
                >
                  <Icon color="primary">mail_outline</Icon>
                </IconButton>
                {value}
              </Fragment>
            );
          } else {
            return null;
          }
        }
      case "dokumenti":
        return ({ value, row }) => {
          if (value && customFn) {
            return (
              <IconButton
                aria-label={"mail"}
                size="small"
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (row.original.id)
                    customFn(row.original.id as number);
                }}
              >
                <Icon fontSize="small" color="primary">file_download</Icon>
              </IconButton>
            )
          } else {
            return null;
          }
        }
      case "iconStatus":
        return ({value}) => {
          const { items } = field;
          if (!items) return null;

          const index = items.values.indexOf(value);
          const valLabel = this.t(items.labels[index]);

          const { icons } = items;
          if (!icons) return null;

          const icon = icons[index];
          const color = helpers.retrieveIconColor(icon);

          return (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Icon style={{ color: color }}>{icon}</Icon>
                <div style={{ fontSize: "14px", marginLeft: "4px" }}>
                  {valLabel}
                </div>
              </div>
          );
        }
      case "numeric":
        return ({ value }) => {
          return (
            <div style={{ textAlign: "end" }}>{value}</div>
          )
        }
      default:
        return ({ value }) => String(value);
    }
  }
}

export default tableGenerator;
