import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import appValidator from '@/lib/appValidator';
import confirmDialog from '@/lib/confirmDialog';
import dataControllerSubModel from "@/lib/dataControllerSubModel";

import radio from "@/models/submodels/radio";
import checkbox from "@/models/submodels/checkbox";
import picker from "@/models/submodels/picker";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import { DoCloseResponse, DoCloseResponseFail, DoCloseResponseSuccess, DoDeleteResponse, DoInsertResponse, DoInsertResponseFail, DoInsertResponseSuccess, DoUpdateResponse, DoUpdateResponseFail, DoUpdateResponseSuccess, FormControllerProps, PickerDataControllers, ServerErrorPromise } from "@/@types/components/formController";
import { FieldAny, IFieldPickerModel, IFieldsCollection } from "@/@types/models/model";
import { DCFieldValue, DCInsertResponseSuccess, DCRecord, DCResponseFail } from "@/@types/lib/dataController";
import { RecordValidation } from "@/@types/lib/appValidator";
import { ConfirmResult } from "@/@types/lib/confirmDialog";
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";

function useFormController(props: FormControllerProps) {

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const [ validation, setValidation] = useState<RecordValidation>({});
  const [ validated, setValidated] = useState(false);
  const [ subModels, setSubModels] = useState<PickerDataControllers>({});
  const [ dataChanged, setDataChanged ] = useState(false);
  const [ fields, setFields ] = useState<IFieldsCollection>([]);
  const [ record, setRecord] = useState<DCRecord>({});
  const [ isLoading, setIsLoading] = useState(false);

  const [ lastChangedField, setLastChangedField] = useState<string>();
  const [ lastChangeAt, setLastChangeAt] = useState<Date>();

  const [ originalRecord, setOriginalRecord] = useState<DCRecord>({});

  const { t } = useTranslation();

  const { dc, form, mode, recordId, defaultValues, initialRecord, onDataUpdate, secondary, fieldNames, controllerForm } = props;

  const validator = new appValidator(t);

  let mounted = false;

  useEffect(() => {
    mounted = true;
    const secondaryPath = secondary && recordId !== undefined ? secondary.replace("__split__", recordId.toString()) : undefined;

    if (recordId !== undefined) {

      dc.GetDataSingle(recordId, secondaryPath)
        .then(rec => {
          if (defaultValues && mode === "insert") {
            Object.keys(defaultValues).forEach(key => {
              //@ts-ignore
              if (!record.hasOwnProperty(key) || rec[key] === undefined || rec[key] === null) {
                record[key] = defaultValues[key];
              }
            })
          }

          const recordCopy = Object.assign({}, rec);
          const originalRecord = Object.assign({}, rec);

          setRecord(recordCopy);
          setOriginalRecord(originalRecord);

        })
        .catch(err => {
          console.warn("Error fetching record from formController ", err);
        });

    } else if (initialRecord !== undefined) {
      const recordCopy = Object.assign({}, initialRecord);
      const originalRecord = Object.assign({}, initialRecord);

      setRecord(recordCopy);
      setOriginalRecord(originalRecord);

    } else if (mode === "insert") {
      const rec = getDefaultValues();
      const recordCopy = Object.assign({}, rec);
      const originalRecord = Object.assign({}, rec);

      setRecord(recordCopy);
      setOriginalRecord(originalRecord);
    }

    const fs = getFields();
    setFields(fs);

    const fieldsWithModel = getFieldsWithModel();

    Promise.all(fieldsWithModel).then(fModels => {
      const subModels = {};
      fModels.forEach(f => {
        const modelType = f.type === 'picker' ? picker
          : f.type === 'radio' ? radio
          : f.type === 'checkbox' ? checkbox
          : null;
        if (modelType !== null) {
          Object.assign(subModels, {[f.source]: new dataControllerSubModel(modelType, f)})
        }
      })
      setSubModels(subModels);
    })
    .catch(err => {
      console.error("Error catching submodels", err);
    })

    return () => { mounted = false; }
  }, []);

  useEffect(() => {
    if (validated && lastChangedField !== undefined) {
      validateField(lastChangedField);
    }
    setDataChanged(checkIfChanged(record));

  }, [lastChangeAt, lastChangedField])

  const getDefaultValues = () => {
    const rec = {};

    //set default values from fields
    dc.fields.forEach(f => {
      if( f.items && f.items.hasOwnProperty('default') ){
        if ( f.type === 'boolean' ){
          //@ts-ignore
          rec[ f.source ] = f.items.default;
        } else if ( f.type === 'radio'){
          if (f.items.default !== undefined) {
            const pos = f.items.values.indexOf( f.items.default );
            //@ts-ignore
            rec[ f.source ] = {
              label: t( f.items.labels[ pos ] ),
              value: f.items.default
            }
          }
        } else if (f.type === 'checkbox') {
          const valueArray = Array.isArray(f.items.default) ? f.items.default : [f.items.default];
          //@ts-ignore
          rec[ f.source ] = valueArray;
        }
      }
    })

    //set default values from props
    if (defaultValues !== undefined && defaultValues !== null) {
      Object.keys(defaultValues).forEach(key => {
        //@ts-ignore
        rec[key] = defaultValues[key];
      })
    }

    return rec;
  }

  const getFieldsWithModel = () => {
    const fieldModels = fields.filter(f => f.hasOwnProperty("subModel")).map(f => f as IFieldPickerModel);
    return fieldModels;
  }

  const getFields = () => {
    let formId = form ? form : mode;

    let fs: IFieldsCollection = [];

    //get all fields for controller
    if (controllerForm) {
      fs = dc.getFormFields(controllerForm);
    }

    //override with custom definitions
    let formFields = dc.getFormFields(formId);
    if (formFields.length > 0) {
      return formFields
    }
    formFields.forEach(f => {
      const ind = fields.findIndex(x => x.source == f.source);
      if (ind >= 0) {
        fs[ind] = f;
      } else {
        fs.push(f);
      }
    })

    //filter by fieldnames if necessary
    if (fieldNames) {
      return fs.filter(x => fieldNames.indexOf(x.source) >= 0);
    } else {
      return fs;
    }
  }

  const checkIfChanged = (record: DCRecord) => {
    const changed = validator.checkIfRecordChanged(record, originalRecord);
    return changed;
  }

  const validateField = (source: string) => {
    const field = fields.find(f => f.source === source);

    if (field !== undefined) {
      const fieldValidation = validator.validateField(record, field);

      setValidation((prevState) => ({
        ...prevState,
        [source]: fieldValidation
      }))
    }
  }

  const getValidator = () => {

    const formId = form ? form : mode;
    const validateFunc = dc.getValidator(formId);
    if (validateFunc !== null && validateFunc !== 't') {
      if (validator[validateFunc] !== undefined && typeof validator[validateFunc] === 'function') {
        return validator[validateFunc];
      } /*else {
        // return (record: any, t: any) => ({});
      }*/
    } /*else {
      // return (record: any, t: any) => ({});
    }*/
    return null;
  }

  const validate = () => {
    //model validation
    let validation = validator.validateModel(record, fields);

    //custom validation
    const validatorFn = getValidator();
    const customValidation = validatorFn !== null ? validatorFn(record, t) : {};

    const finalValidation = validator.mergeValidation(validation, customValidation);
    const isValid = validator.checkIfValid(finalValidation);

    setValidation(finalValidation);
    setValidated(true);

    return isValid;
  }

  const serverErrorPromise = (serverValidation: RecordValidation): Promise<ServerErrorPromise> => {
    setValidation(serverValidation);
    return Promise.resolve({
      success: false,
      validationPass: false,
      validation: serverValidation
    });
  }

  // *** Confirmations ***

  const confirmUpdate = () => {

    const checkFields = fields.filter(f => f.validation && f.validation.confirmChange);
    const changedFields = checkFields.filter(f => validator.checkIfFieldChanged(record[f.source], originalRecord[f.source]));

    const changePromises = changedFields.map(f => new Promise(resolve => {
      return confirmUpdateField(f)
      .then((resp) => {return Promise.resolve({confirmed: true})})
      .catch(err => {return Promise.resolve({canceled: true})})
    }))

    return Promise.all(changePromises).
    then(resp => {
      return Promise.resolve({confirmed: true});
    })
    .catch(err => {
      return Promise.reject({canceled: true});
    })
  }

  const confirmUpdateField = (field: FieldAny): Promise<ConfirmResult> => {

    const oldValue = originalRecord[field.source];
    const newValue = record[field.source];

    const title = "Želite li doista izmijeniti vrijednost polja '" + field.title + "'?";
    const text = "Stara vrijednost: " + oldValue + ", nova vrijednost: " + newValue;
    const type = "warning";
    const confirmButtonText = "Da, izmijeni";
    const cancelButtonText = "Ne, odustani";

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  }

  const confirmDelete = (): Promise<ConfirmResult> => {
    const title = t("cdialogs.are_you_sure");
    const text = t("cdialogs.once_deleted")
    const type = "warning";
    const confirmButtonText = t("cdialogs.yes_delete")
    const cancelButtonText = t("cdialogs.no_cancel")

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  }

  const confirmUpdateClose = (): Promise<ConfirmResult> => {
    const title = t("cdialogs.changes");
    const text = t("cdialogs.want_to_save");
    const type = "warning";
    const confirmButtonText = t("cdialogs.yes_save_changes");
    const cancelButtonText = t("cdialogs.no_just_close");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  }

  const confirmInsertClose = (): Promise<ConfirmResult> => {
    const title = t("cdialogs.changes");
    const text = t("cdialogs.close_text");
    const type = "warning";
    const confirmButtonText = t("cdialogs.continue");
    const cancelButtonText = t("cdialogs.close");

    return confirmDialog(title, text, type, confirmButtonText, cancelButtonText);
  }


  // *** Export functions ***

  const onFieldChange = (value: DCFieldValue, source: string) => {

    setRecord((prevState) => ({
      ...prevState,
      [source]: value
    }));

    setLastChangedField(source);
    setLastChangeAt(new Date());
  }

  const doInsert = (): Promise<DoInsertResponse> => {

    const isValid = validate();

    if (isValid) {
      setIsLoading(true);
      return dc.InsertRecord(record)
        .then((response) => {
          const id = (response as DCInsertResponseSuccess).id;
          if (onDataUpdate) {
            onDataUpdate();
          }
          return Promise.resolve({ success: true, id: id } as DoInsertResponseSuccess);

        })
        .catch((response) => {
          switch ((response as DCResponseFail).errorCode) {
            case 401:
              return serverErrorPromise({
                email: {
                  valid: false,
                  msg:
                    "Korisnik s navedenom e-mail adresom je već registriran u sustavu."
                }
              }) as Promise<DoInsertResponseFail>;
            }
          return Promise.resolve({
            success: false,
            validationPass: true,
            error: "Greška prilikom spremanja novih podataka! " + ( response.error? response.error : '')
          } as DoInsertResponseFail)
        })
        .finally(() => {
          if (mounted) {
            setIsLoading(false);
          }
        })
    } else {
      if (snackbarContext !== null) {
        snackbarContext.showNotification("messages.required_fields", "warning");
      }
      return Promise.resolve({ success: false, validationPass: false })
    }
  }

  const doUpdate = (): Promise<DoUpdateResponse> => {
    const id = recordId || record["id"] as number;
    if (id === undefined || id === null) {
      return Promise.reject({success: false, error: "Unknown id"});
    }

    const isValid = validate();

    if (isValid) {
      setIsLoading(true);
      return confirmUpdate()
        .then((result: ConfirmResult) => {
          if (result.canceled) {
            return Promise.reject({ success: false, canceled: true });
          }
          else if (result.confirmed) {
            return dc.UpdateRecord(id, record)
              .then((response) => {
                setDataChanged(false);
                setValidated(false);

                if (onDataUpdate) {
                  onDataUpdate();
                }

                return Promise.resolve({ success: true, updated: true } as DoUpdateResponseSuccess);

              })
              .catch((x) => {
                return Promise.reject({ success: false, validationPass: true, error: "Greška prilikom ažuriranja podataka!" } as DoUpdateResponseFail)
              })
              .finally(() => {
                if (mounted) {
                  setIsLoading(false);
                }
              })
          } else {
            return Promise.reject({ success: false, error: "Unknown" } as DoUpdateResponseFail);
          }
        })
        .catch((err: ConfirmResult) => {
          return Promise.reject({success: false, canceled: true})
        });

    } else {
      if (snackbarContext !== null) {
        snackbarContext.showNotification("messages.required_fields", "warning");
      }
      return Promise.resolve({ success: false, validationPass: false, validation: validation });
    }

  }

  const doDelete = (): Promise<DoDeleteResponse> => {
    const id = recordId || record["id"] as number;
    if (id === undefined || id === null) {
      return Promise.reject({success: false, error: "Unknonw id"});
    }

    return confirmDelete()
      .then(result => {
        if (result.canceled) {
          return Promise.reject({ success: false, canceled: true });
        }
        if (result.confirmed) {
          return dc.DeleteRecord(id)
            .then(response => {
              if (onDataUpdate) {
                onDataUpdate();
              }
              return Promise.resolve({ success: true });
            })
            .catch(response => {
              return Promise.reject({ success: false, error: response.error ? response.error : "Greška prilikom brisanja podataka" });
            })

        }
        else {
          return Promise.reject({ success: false });
        }
      })

  }

  const doClose = (externalDataChanged: boolean = false): Promise<DoCloseResponse> => {

    if (dataChanged || externalDataChanged) {
      if (mode === "insert") {
        return confirmInsertClose()
          .then(result => {
            if (result.canceled || !result.confirmed) {
              return Promise.reject({ success: false, canceled: true } as DoCloseResponseFail);
            } else {
              return Promise.resolve({ success: true } as DoCloseResponseSuccess);
            }
          })
      } else if (mode === "update") {
        return confirmUpdateClose()
          .then(result => {
            if (result.canceled) {
              return Promise.reject({ success: false, canceled: true } as DoCloseResponseFail);
            }
            else if (!result.confirmed) {
              return Promise.reject({ success: false, shouldSave: true } as DoCloseResponseFail);
              //return doUpdate();
            } else {
              return Promise.resolve({ success: true } as DoCloseResponseSuccess);
            }
          });
      } else {
        return Promise.resolve({ success: true} as DoCloseResponseSuccess);
      }
    } else {
      return Promise.resolve({ success: true } as DoCloseResponseSuccess);
    }
  }

  const doClear = () => {
    setRecord({});
    return Promise.resolve({ success: true });
  }

  const doPrepareSearch = () => {
    const isValid = validate();

    if (isValid) {
      return Promise.resolve({ success: true, filters: record })
    }
    else {
      if (snackbarContext !== null) {
        snackbarContext.showNotification("messages.required_fields", "warning");
      }
      return Promise.resolve({ success: false, validationPass: false });
    }
  }

  const doRefresh = () => {
    if (recordId) {

      dc.GetDataSingle(recordId)
      .then(rec => {
        const recordCopy = Object.assign({}, rec);
        const originalRecord = Object.assign({}, rec);

        setRecord(recordCopy);
        setOriginalRecord(originalRecord);

      })
      .catch(err => {
        console.warn("Error refreshing record from formController ", err);
      });
    }
  }


  return {
    validation,
    validated,
    subModels,
    dataChanged,
    fields,
    record,
    isLoading,

    onFieldChange,
    doInsert,
    doUpdate,
    doDelete,
    doClose,
    doClear,
    doPrepareSearch,
    doRefresh
  }

}

export default useFormController;
