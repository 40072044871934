import { Permissions as P } from "@/lib/permissions";
import { MenuType } from "@/@types/common";

const menuRoutes: MenuType[] = [
	{ path: "/map", name: "map", ttoken: "menu.map", icon: "map" },
	{ path: "/3d", name: "3D", ttoken: "menu.3d", icon: "public"},
	// { path: "/rasvjeta", name: "komunalni-obveznici", ttoken: "menu.rasvjeta", icon: "light", needPermission:[P.Rasvjeta] },
	// { path: "/fotodokumentacija-rasvjeta", name: "processing", ttoken: "menu.rasvjeta_processing", icon: "picture_in_picture", needPermission:[P.Rasvjeta] },
	{ path: "/objekti", name: "objekti", ttoken: "menu.objekti", icon: "business" },
	{ path: "/signalizacija", name: "signalizacija", ttoken: "menu.signalizacija", icon: "signpost" },
	// { path: "/admin/users", name: "users", ttoken: "menu.users", icon: "group", needPermission:[P.Administration] },
];

export default menuRoutes;
