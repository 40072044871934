import Controls from "./Controls";
import DefaultControls from "./DefaultControls";

//OpenLayers Control Wrappers
import AttributionControl from "./OpenLayers/AttributionControl";
import FullScreenControl from "./OpenLayers/FullScreenControl";
import MousePositionControl from "./OpenLayers/MousePositionControl";
import OverviewMapControl from "./OpenLayers/OverviewMapControl";
import RotateControl from "./OpenLayers/RotateControl";
import ScaleLineControl from "./OpenLayers/ScaleLineControl";
import ZoomSliderControl from "./OpenLayers/ZoomSliderControl";
import ZoomToExtentControl from "./OpenLayers/ZoomToExtentControl";
import ZoomControl from "./OpenLayers/ZoomControl";

//OpenLayers-Ext Control Wrappers
import LayerSwitcherImageControl from "./OpenLayersExt/LayerSwitcherImageControl";
import ScaleControl from "./OpenLayersExt/ScaleControl";
import ButtonControl from "./OpenLayersExt/ButtonControl";
import ToggleControl from "./OpenLayersExt/ToggleControl";
import BarControl from "./OpenLayersExt/Bar/BarControl";
import SearchControl from "./OpenLayersExt/SearchControl";

//Sidebar
import SidebarControl from "./Sidebar/SidebarControl";

//Custom Controls
import GeoLocateControl from "./Custom/GeoLocateControl";
import LayerTree from "./Custom/LayerTree/LayerTree";
import MeasureControls from "./Custom/MeasureControls";
import PanningControl from "./Custom/PanningControl";
import CenterMarkerControl from "./Custom/CenterMarker/CenterMarkerControl";
import StatusControl from "./Status/StatusControl";
import CoordZoomStatusControl from "./CoordZoomStatus/CoordZoomStatusControl";
import ScaleRatioControl from "./ScaleRatio/ScaleRatioControl";
import ViewHistoryControl from "./Custom/ViewHistoryControl";
import AttributeTableControl from "./AttributeTable/AttributeTableControl";

export {
  Controls,
  DefaultControls,

  AttributionControl,
  FullScreenControl,
  MousePositionControl,
  OverviewMapControl,
  RotateControl,
  ScaleLineControl,
  ZoomSliderControl,
  ZoomToExtentControl,
  ZoomControl,

  LayerSwitcherImageControl,
  ScaleControl,
  ScaleRatioControl,
  ButtonControl,
  ToggleControl,
  BarControl,
  SearchControl,

  SidebarControl,

  GeoLocateControl,
  LayerTree,
  MeasureControls,
  PanningControl,
  CenterMarkerControl,
  StatusControl,
  CoordZoomStatusControl,
  ViewHistoryControl,
  AttributeTableControl
};
