import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers ext
import Scale from "ol-ext/control/Scale";
import "ol-ext/control/Scale.css";

//Custom componets
import { registerOlControl } from "@/lib/olHelpers";
import MapContext from "@/components/Map/MapContext";

//Types
import { IScaleControl } from "@/@types/components/Map/Controls/OpenLayersExt";
import { MapContextType } from "@/@types/context/MapContext";

const ScaleControl: FunctionComponent<IScaleControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    className: undefined,
    ppi: undefined,
    editable: undefined
  };

  const events = {
    select: undefined,
    "change:input": undefined
  };

  useEffect(() => registerOlControl(context, Scale, props, options, events), [
    props.className,
    props.ppi,
    props.editable
  ]);

  return null;
};

export default ScaleControl;

