import React, { useState, useEffect, useContext } from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//Material UI Core Elements
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//Material UI Icons
import AssignmentIcon from "@mui/icons-material/Assignment";

//Custom Components
import CoordZoomStatusRoot from "./CoordZoomStatusRoot";
import MapContext from "@/components/Map/MapContext";

//Openlayers
import { toStringHDMS as OlToStringHDMS } from "ol/coordinate";
import { transform } from "ol/proj";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { ICordZoomStatusControl, CoordinateSystemType } from "@/@types/components/Map/Controls/CordZoomStatus";
import { MapEvent } from "ol";

const CoordZoomStatusControl: FunctionComponent<ICordZoomStatusControl> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { t } = useTranslation();

  const [center, setCenter] = useState<number[] | undefined>(undefined);
  const [zoom, setZoom] = useState<number | undefined>(undefined);

  const { chosenCoordinateSystem } = props;

  // const [chosenCoordinateSystem, setChosenCoordinateSystem] = useState<CoordinateSystemType>("WGS84");

  useEffect(() => {
    if (mapContext.map) {
      mapContext.map.on("moveend", handleMoveEnd);
    }

    return () => {
      if (mapContext.map) {
        mapContext.map.un("moveend", handleMoveEnd);
      }
    };
  }, [mapContext.map]);

  const handleMoveEnd = (evt: MapEvent) => {
    const view = evt.map.getView();
    if (view) {
      setCenter(view.getCenter());
      setZoom(view.getZoom());
    }
  };

  const fontSize = 14;

  const displayCoords = (type: "HTRS96/TM" | "WGS84" | "Gauss Kruger 6"): string => {
    if (center) {
      switch (type) {
        case "HTRS96/TM":
          let htrs_array = transform(center, "EPSG:3857", "EPSG:3765");
          return htrs_array[0].toFixed(2) + ", " + htrs_array[1].toFixed(2);
        case "WGS84":
          return OlToStringHDMS(transform(center, "EPSG:3857", "EPSG:4326"), 2);
        case "Gauss Kruger 6":
          let gk_array = transform(center, "EPSG:3857", "EPSG:31276");
          return gk_array[0].toFixed(2) + ", " + gk_array[1].toFixed(2);
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  const copyCoordinates = (): void => {
    navigator.clipboard.writeText(displayCoords(chosenCoordinateSystem) as string);
  };

  const textCenter = `${t("coordzoomstatus.center")}: ${displayCoords(chosenCoordinateSystem)} `;
  const textZoom = `${t("coordzoomstatus.zoom")}: ${zoom ? zoom.toFixed(1).toString() : ""}`;

  return (
    //@ts-ignore TODO: ref is missing in type ICordZoomStatusRoot, probably need to pass down ref
    <CoordZoomStatusRoot>
      <Box m={0} style={{ paddingLeft: 5 }}>
        <Tooltip title={t("coordzoomstatus.copy") as string}>
          <IconButton size="small" onClick={copyCoordinates}>
            <AssignmentIcon style={{ fontSize: fontSize }} />
          </IconButton>
        </Tooltip>
        <Typography variant="caption">
          {textCenter}
          {textZoom}
        </Typography>
      </Box>
    </CoordZoomStatusRoot>
  );
};

export default CoordZoomStatusControl;
