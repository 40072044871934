import React, { useContext } from "react";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

//Custom Components
import { Permissions as P } from "@/lib/permissions";
import { appHeaderTheme } from "@/components/ThemeContext/ThemeProvider";
import HeaderMenuButton from "@/ui/Header/HeaderMenuButton";
import AppHeaderMenuButton from "@/ui/Header/AppHeaderMenuButton";
import ThemeSwitch from "@/ui/Header/ThemeSwitch";
import LanguageSelector from "@/ui/Header/LanguageSelector";
import ProfileMenu from "@/ui/Header/ProfileMenu";
import ModuleMenu from "@/ui/Header/ModuleMenu";
import AccessibilityMenu from "@/ui/Header/AccessibilityMenu";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import UserContext from "@/components/UserContext/UserContext";
import NavDrawerContext from "@/components/NavDrawerContext/NavDrawerContext";

//Types
import { Box } from "@mui/material";

export interface IAppHeader {
  modul?: "admin" | "gis" | string
}

const AppHeader: FunctionComponent<IAppHeader> = (props) => {
  const userContext = useContext(UserContext);
  const navDrawerContext = useContext(NavDrawerContext);
  const { t } = useTranslation();
  const { modul } = props;
  const { sidebarOpen, toggleSidebar} = navDrawerContext;
  const theme = useTheme();
  const isSmallScreen: boolean = useMediaQuery(theme.breakpoints.down("md"));

  const drawerWidth = 240;

  const toolbarStyle = {
    "& > button": {
      mx: { sm: 0, md: 1 }
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    // borderBottom: "2px",
    borderStyle: "solid",
    borderColor: "primary.main"
  };

  const appBarStyle = {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: sidebarOpen ? drawerWidth : 0,
    width:  isSmallScreen ? (("100%" )): (sidebarOpen ? `calc(100% - ${drawerWidth}px)` : "100%"),
    transition: sidebarOpen
      ? theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      : theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
  };

  const opcina = userContext?.tenantData?.naziv;
  
  const longTitle = (opcina ? `${opcina} - ` : "") + t("app_title")
  const shortTitle = t("app_title_short");

  // {useMediaQuery(theme.breakpoints.down("sm"))? !sidebarClose : sidebarOpen}

  return (
    <MuiThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          secondary: appHeaderTheme.palette.secondary
        }
      })}
    >
      <AppBar position="fixed" sx={appBarStyle} color="secondary">
        <Toolbar variant="dense" disableGutters={true} sx={toolbarStyle}>
        <Box m={0} sx={{ display: { xs: "block", md: "none" } }}>
            <AppHeaderMenuButton showCloseIcon={true} />
          </Box>
          {!sidebarOpen ? (
            <Box sx={{display: { xs: "none", md: "block"}}}>
              <HeaderMenuButton toggleSidebar={toggleSidebar} />
            </Box>
          ) : null}
          <ToolbarFillContent>
            <Typography variant="h6" component="h2" style={{ color: "#fff", fontSize: "1.15rem" }}>
              <NavLink
                to="/"
                style={{
                  color: "#fff",
                  textDecoration: "none"
                }}
              >
                {isSmallScreen ? shortTitle : longTitle}
              </NavLink>
            </Typography>
          </ToolbarFillContent>
          <LanguageSelector />
          <AccessibilityMenu />
          <ThemeSwitch />
          <ProfileMenu />
          {/* <ModuleMenu needPermission={[P.Administration]} /> */}
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};

export default AppHeader;
