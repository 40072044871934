import React, { FunctionComponent, useRef } from "react";

//Custom Components
import AttributeTableRoot from "./AttributeTableRoot";
import AttributeTable from "./AttributeTable";

//Types
import { IAttributeTableControl } from "@/@types/components/Map/Controls/AttributeTable";

const AttributeTableControl: FunctionComponent<IAttributeTableControl> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { dcDictionary, records, selectedRecords, autohide, onSelectRecord, onRemoveRecord, onCustomAction, onClose } = props;

  return (
    <AttributeTableRoot ref={ref.current}>
      <AttributeTable
        dcDictionary={dcDictionary}
        records={records}
        selectedRecords={selectedRecords}
        onSelectRecord={onSelectRecord}
        onRemoveRecord={onRemoveRecord}
        onCustomAction={onCustomAction}
        onClose={onClose}
        autohide={autohide ? true : false}
      />
    </AttributeTableRoot>
  );
};

export default AttributeTableControl;
