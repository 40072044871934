import { FunctionComponent, useContext, useEffect } from "react";

import { Snap } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { ISnapInteraction } from "@/@types/components/Map/Interactions/OpenLayers";

const SnapInteraction: FunctionComponent<ISnapInteraction> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    features: undefined,
    edge: undefined,
    vertex: undefined,
    pixelTolerance: undefined,
    source: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Snap, props, options, events),
    []
  );

  return null;
};

export default SnapInteraction;

