import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

//Material-UI Core Components
import Typography from "@mui/material/Typography";

//Custom Components
import PickerControl from "@/controls/PickerControl";
import picker from "@/models/submodels/picker";
import api from "@/lib/api";
import { GridContainer, GridItem } from "@/ui/Grid";
import dataControllerSubModel from "@/lib/dataControllerSubModel";

//Types
import { IFilterPane } from "@/@types/components/MapSidebarPanes";
import { PickerItem } from "@/@types/controls/controls";
import { IFieldPickerModel, PickerItemValue } from "@/@types/models/model";

const FilterPane: FunctionComponent<IFilterPane> = (props) => {

  const [value, setValue] = useState<PickerItem | null>(null);
  const [subModels, setSubModels] = useState<object | null>(null);

  const apiInstance = new api();

  const field: IFieldPickerModel = {
    title: "Lokacija",
    source: "lokacija_id",
    ttoken: "map.search_location",
    type: "picker",
    subModel: {
      apiPath: "map/locations",
      format: "{label}"
    },
    width: "full"
  };

  const handleChange = (option: PickerItem | PickerItemValue | null, source: string): void => {
    const { onChange } = props;

    if (option === null) {
      if (onChange) {
        onChange(null);
      }
      setValue(null);
    }
    else if (option && option.hasOwnProperty("value")) {
      const code = (option as PickerItem).value;
      const url = "map/locations/" + code;
      apiInstance.Call(url, "get").then(resp => {
        if (resp.success) {
          const geojson = resp.data;
          if (onChange) {
            onChange(geojson as object);
          }
        } else {
          if (onChange) {
            onChange(null);
          }
        }
      })
      setValue(option as PickerItem);
    } else {
      if (onChange) {
        onChange(null);
      }
      setValue(null);
    }
  }

  useEffect(() => {
    Promise.all([field])
      .then((fModels) => {
        const subModels = {};
        fModels.forEach((f) => {
          const modelType = f.type === "picker" ? picker : null;
          if (modelType !== null) {
            Object.assign(subModels, {
              [f.source]: new dataControllerSubModel(modelType, f as IFieldPickerModel)
            });
          }
        });
        setSubModels(subModels);
      })
      .catch((err) => {
        console.error("Error catching submodels", err);
      });
  }, [])

  const model =
    subModels && subModels.hasOwnProperty("lokacija_id")
      //@ts-ignore
      ? subModels["lokacija_id"]
      : undefined;

  const textVariant = "body2";

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Typography variant="h6" component="h2" gutterBottom={true}>
          Lokacijski
        </Typography>
        <Typography variant={textVariant} paragraph={true}>
          Odaberite županiju, grad, općinu ili naselje:
        </Typography>
        <PickerControl
          field={field}
          formMode="form"
          controlMode="edit"
          value={value}
          isLoading={!subModels}
          //@ts-ignore TODO: Once PickerControl has new picker and changed to TS, we can remove ignore
          model={model}
          onChange={handleChange}
        />
      </GridItem>
      <GridItem xs={12}>
        <Typography variant="h6" component="h2" gutterBottom={true}>
          Prema podacima opažanja
        </Typography>
        <Typography variant={textVariant} paragraph={true}>
          Filtrirajte prema opažanjima...
        </Typography>
      </GridItem>
    </GridContainer>
  );
};

export default FilterPane;

