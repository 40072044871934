import * as React from "react";
import { FunctionComponent, SyntheticEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

//MUI
import { useTheme } from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";

//MUI Lab
import TreeItem from "@mui/lab/TreeItem";

//MUI Icons
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

//Custom components
import MapContext from "@/components/Map/MapContext";
import LayerTreeItemCustomContent from "@/components/Map/Controls/Custom/LayerTree/LayerTreeItemCustomContent";
import { findLayer, flattenLayers } from "@/lib/olHelpers";

//Openlayers
import OlLayerGroup from "ol/layer/Group";
import OlBaseLayer from "ol/layer/Base";

//Types
import { ILayerTreeItem } from "@/@types/components/Map/Controls/Custom/LayerTree";
import { MapContextType } from "@/@types/context/MapContext";

const LayerTreeItem: FunctionComponent<ILayerTreeItem> = (props) => {
  const { t } = useTranslation();

  const mapContext = useContext(MapContext) as MapContextType;

  const theme = useTheme();

  const { layer, onNodeSelect } = props;

  const visibility = mapContext.getLayerVisibility();

  const handleHelp = (evt: SyntheticEvent): void => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (onNodeSelect) {
      onNodeSelect(evt, id);
    }
  }

  const id = layer.get("id");
  const layer_title = layer.get("title") as string;
  const layer_title_ttoken = layer.get("ttoken") as string;
  const title = layer_title_ttoken ? t(layer_title_ttoken) : layer_title;
  // const info = Math.floor(Math.random() * 100);
  const visible = visibility.hasOwnProperty(id) && visibility[id] === false ? false : true;

  const show = title && title.length > 0;
  if (!show) {
    return null;
  }

  const isGroupLayer = layer instanceof OlLayerGroup;

  const directChildLayers = isGroupLayer ? layer.getLayers().getArray() : null;
  const allChildLayers = isGroupLayer ? flattenLayers(directChildLayers, 5) : null;

  const isVisible = (layer: OlBaseLayer) => {
    const id = layer.get("id");
    return visibility.hasOwnProperty(id) && visibility[id] === false ? false : true;
  };

  const childsVisible = allChildLayers
    ? allChildLayers.filter((x) => isVisible(x) === true && !(x instanceof OlLayerGroup)).length
    : null;
  const childsHidden = allChildLayers
    ? allChildLayers.filter((x) => isVisible(x) === false && !(x instanceof OlLayerGroup)).length
    : null;

  const groupChecked = childsVisible ? childsVisible > 0 : false;
  const groupIndeterminate = childsVisible && childsHidden ? childsVisible > 0 && childsHidden > 0 : false;

  const iconColor = "primary";

  const LabelIcon = isGroupLayer ? (
    groupIndeterminate ? (
      <IndeterminateCheckBoxIcon color={iconColor} />
    ) : groupChecked ? (
      <CheckBoxIcon color={iconColor} />
    ) : (
      <CheckBoxOutlineBlankIcon color={iconColor} />
    )
  ) : visible ? (
    <CheckBoxIcon color={iconColor} />
  ) : (
    <CheckBoxOutlineBlankIcon color={iconColor} />
  );
  const shouldRenderHelp = false;

  const checked = isGroupLayer ? (groupIndeterminate ? true : groupChecked) : visible;

  return (
    <TreeItem
      ContentComponent={LayerTreeItemCustomContent}
      ContentProps={{
        // @ts-ignore   - Used ts-ignore rather than aria-checked or allowed props for clarity
        checked: checked
      }}
      nodeId={id}
      label={
        <FormControlLabel
          label={title}
          control={
            <CheckBox
              color="primary"
              checked={checked}
              indeterminate={groupIndeterminate}
              onChange={handleChange}
              //onClick={(e) => e.stopPropagation()}
            />
          }
        />
      }
    >
      {isGroupLayer && directChildLayers
        ? directChildLayers.map((l, i) => {
            return <LayerTreeItem key={i} layer={l} onNodeSelect={onNodeSelect}/>;
          })
        : null}
    </TreeItem>
  );

  // return (
  //   <TreeItem
  //     nodeId={id}
  //     label={
  //       <div
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           padding: theme.spacing(0.5, 0)
  //         }}
  //       >
  //         {LabelIcon}
  //         <Typography
  //           variant="body2"
  //           sx={{
  //             fontWeight: "inherit",
  //             flexGrow: 1
  //           }}
  //         >
  //           {t(title)}
  //         </Typography>
  //         {shouldRenderHelp ? (
  //           <IconButton
  //             size="small"
  //             onClick={handleHelp}
  //             sx={{
  //               color: "#FFFFFF00"
  //             }}
  //           >
  //             <Icon style={{ fontSize: "1rem" }}>help</Icon>
  //           </IconButton>
  //         ) : null}
  //         {/* <Typography variant="caption" color="inherit" sx={{color: "#ccc"}}>
  //           {t(info)}
  //         </Typography> */}
  //       </div>
  //     }
  //     sx={{
  //       ".MuiTreeItem-root": {
  //         color: "text.secondary"
  //       },
  //       ".MuiTreeItem-content": {
  //         color: "text.secondary",
  //         borderTopRightRadius: 2,
  //         borderBottomRightRadius: 2,
  //         pr: 1,
  //         fontWeight: theme.typography.fontWeightMedium,
  //         // "$expanded > &": {
  //         //   fontWeight: theme.typography.fontWeightRegular
  //         // }
  //         "&:hover $iconInfo": {
  //           color: "primary.main"
  //         }
  //       },
  //       ".Mui-expanded": {},
  //       ".MuiTreeItem-label": {
  //         fontWeight: "inherit",
  //         color: "inherit"
  //       }
  //     }}
  //   >
  //     {isGroupLayer && directChildLayers
  //       ? directChildLayers.map((l, i) => {
  //           return <LayerTreeItem key={i} layer={l} />;
  //         })
  //       : null}
  //   </TreeItem>
  // );
};

export default LayerTreeItem;
