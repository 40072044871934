import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers ext
import Search from "ol-ext/control/Search";
import "ol-ext/control/Search.css";

//Custom components
import MapContext from "@/components/Map/MapContext";
import BarControlContext from "./Bar/BarControlContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { BarControlContextType } from "@/@types/components/Map/Controls/OpenLayersExt/Bar";
import { ISearchControl } from "@/@types/components/Map/Controls/OpenLayersExt";

const SearchControl: FunctionComponent<ISearchControl> = (props) => {
  const mapContext = useContext(MapContext) as  MapContextType;
  const parentBarContext = useContext(BarControlContext) as BarControlContextType;

  const options = {
    className: undefined,
    target: undefined,
    label: undefined,
    placeholder: undefined,
    reverse: undefined,
    inputLabel: undefined,
    noCollapse: undefined,
    typing: undefined,
    minLength: undefined,
    maxItems: undefined,
    maxHistory: undefined,
    getTitle: undefined,
    autocomplete: undefined
  };

  const events = {
    select: undefined,
    "change:input": undefined
  };

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    let control = new Search(definedOptions);

    if(props.id) {
      control.set("id", props.id)
    }

    if (parentBarContext && parentBarContext.exists) {
      parentBarContext.childControls.push(control);

    } else if (mapContext.map) {
      const mapControl = findControl(mapContext.map, props.id, Search);
      if (mapControl) {
        mapContext.map.removeControl(mapControl);
        console.log('control removed', Search);
      }
      mapContext.map.addControl(control);
      console.log('control added', Search);
    } else {
      mapContext.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Search);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    }

  }, [
    props.className,
    props.target,
    props.label,
    props.placeholder,
    props.reverse,
    props.inputLabel,
    props.noCollapse,
    props.typing,
    props.minLength,
    props.maxItems,
    props.maxHistory,
    props.getTitle,
    props.autocomplete
  ])

  return null;
};

export default SearchControl;

