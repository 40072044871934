import { FunctionComponent, useContext, useEffect } from "react";

import { Translate } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const TranslateInteraction: FunctionComponent<any> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    features: undefined,
    layers: undefined,
    filter: undefined,
    hitTolerance: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined,
    translateend: undefined,
    translatestart: undefined,
    translating: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, Translate, props, options, events),
    []
  );

  return null;
};

export default TranslateInteraction;

