import { FunctionComponent, useContext, useEffect } from "react";
import MapContext from "@/components/Map/MapContext";

import Attribution from "ol/control/Attribution";
import { registerOlControl } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IAttributionControl } from "@/@types/components/Map/Controls/OpenLayers";

const AttributionControl: FunctionComponent<IAttributionControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    className: undefined,
    target: undefined,
    collapsible: undefined,
    collapsed: undefined,
    tipLabel: undefined,
    label: undefined,
    collapseLabel: undefined,
    render: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, Attribution, props, options, events), [
    props.className,
    props.target,
    props.collapsible,
    props.collapsed,
    props.tipLabel,
    props.label,
    props.collapseLabel,
    props.render
  ]);

  return null;
};

export default AttributionControl;

